import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import {
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Icon,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Stack,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Textarea,
  Input,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FaSync, FaUserCircle, FaDatabase, FaArrowLeft, FaClipboard, FaCopy, FaDownload, FaFileWord} from 'react-icons/fa';
import jsPDF from 'jspdf';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { Document, Paragraph, Packer, TextRun, HeadingLevel, BorderStyle } from 'docx';


const DocumentStatus = {
  READY: 'Resumen generado',
  PENDING: 'Resumen pendiente',
};

const DocumentType = {
  COMPLEX_SUMMARY: "Resumen Historia Clinica",
  FIRST_VISIT: "Resumen de Primera Visita",
  TRANSCRIPT_SUMMARY: "Resumen Consulta",
};

const MyDocuments = () => {
  const { token } = useAuth();
  const { specialty } = useParams();
  const navigate = useNavigate();

  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedSummary, setEditedSummary] = useState('');
  const [editedPatientName, setEditedPatientName] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const cardBgColor = useColorModeValue("white", "gray.700");

  const [isGeneratingIndications, setIsGeneratingIndications] = useState(false);
  const [generatedIndications, setGeneratedIndications] = useState('');
  const [isIndicationsModalOpen, setIsIndicationsModalOpen] = useState(false);
  const [scrollBehavior, setScrollBehavior] = React.useState('inside')

  const fetchDocuments = async () => {
    if (!token?.user?.email) return;

    const email = encodeURIComponent(token.user.email);
    const db_mongo_url_api = process.env.REACT_APP_DB_MONGO_API;
    const url = `${db_mongo_url_api}/documents/search/${email}?limit=100&offset=0`;

    const fetchPromise = axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then(response => {
        setDocuments(response.data);
        return response.data;
      });

    toast.promise(fetchPromise, {
      success: {
        title: 'Documentos cargados',
        description: 'Los documentos se han cargado exitosamente.',
        position: 'top'
      },
      error: {
        title: 'Error al cargar',
        description: 'No se pudieron cargar los documentos. Por favor, intente de nuevo.',
        position: 'top'
      },
      loading: {
        title: 'Cargando documentos',
        description: 'Por favor, espere mientras se cargan los documentos.',
        position: 'top'
      },
    });

    try {
      await fetchPromise;
    } catch (error) {
      console.error('Error fetching documents:', error.message);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [token]);

  const handleRefresh = () => {
    fetchDocuments();
  };

  const handleViewDocument = document => {
    setSelectedDocument({ ...document });
    setEditedSummary(document.summary || '');
    setEditedPatientName(document.patient_name || '');
    setIsEditing(false);
    onOpen();
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setEditedSummary(selectedDocument?.summary || '');
      setEditedPatientName(selectedDocument?.patient_name || '');
    }
  };

  const handleSummaryChange = event => {
    setEditedSummary(event.target.value);
  };

  const handlePatientNameChange = (event) => {
    setEditedPatientName(event.target.value);
  };

  const handleSaveChanges = async () => {
    const db_mongo_url_api = process.env.REACT_APP_DB_MONGO_API;

    const url = `${db_mongo_url_api}/documents/?id=${selectedDocument.id}`;

    const updateData = {
      patient_name: editedPatientName,
      summary: editedSummary
    };

    const updatePromise = axios.put(url, updateData, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    }).then(response => {
      setSelectedDocument(prev => ({ ...prev, patient_name: editedPatientName, summary: editedSummary }));
      setIsEditing(false);
      // Update the document in the documents list
      setDocuments(prevDocuments => 
        prevDocuments.map(doc => 
          doc.id === selectedDocument.id 
            ? { ...doc, patient_name: editedPatientName, summary: editedSummary } 
            : doc
        )
      );
      return response.data;
    });

    toast.promise(updatePromise, {
      success: { 
        title: 'Cambios guardados', 
        description: 'Los cambios se han guardado exitosamente en la base de datos.',
        position: 'top'
      },
      error: { 
        title: 'Error al guardar', 
        description: 'No se pudieron guardar los cambios. Por favor, intente de nuevo.',
        position: 'top'
      },
      loading: { 
        title: 'Guardando cambios', 
        description: 'Por favor, espere mientras se guardan los cambios.',
        position: 'top'
      },
    });

    try {
      await updatePromise;
    } catch (error) {
    }
  };

  const handleDownloadSummary = () => {
    toast({
      title: 'Resumen descargado',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleDownloadDOCX = async () => {
    // Create a new document
    const doc = new Document({
      sections: [{
        properties: {},
        children: [
          // Title
          new Paragraph({
            text: "Indicaciones para el Paciente",
            heading: HeadingLevel.HEADING_1,
            spacing: {
              after: 200,
            },
            border: {
              bottom: {
                color: "auto",
                space: 1,
                style: BorderStyle.SINGLE,
                size: 6,
              },
            },
          }),

          // Patient info
          new Paragraph({
            children: [
              new TextRun({
                text: `Paciente: ${selectedDocument?.patient_name || 'No disponible'}`,
                bold: true,
                size: 24,
              }),
            ],
            spacing: {
              after: 200,
            },
          }),

          // Date
          new Paragraph({
            children: [
              new TextRun({
                text: `Fecha: ${new Date().toLocaleDateString()}`,
                bold: true,
                size: 24,
              }),
            ],
            spacing: {
              after: 400,
            },
          }),
        ],
      }],
    });

    // Convert markdown to plain text sections
    const html = marked(generatedIndications);
    const div = document.createElement('div');
    div.innerHTML = DOMPurify.sanitize(html);

    // Function to process nodes and add them to the document
    const processNode = (node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const tagName = node.tagName.toLowerCase();
        
        switch (tagName) {
          case 'h1':
            return new Paragraph({
              text: node.textContent,
              heading: HeadingLevel.HEADING_1,
              spacing: { before: 240, after: 120 },
            });
          case 'h2':
            return new Paragraph({
              text: node.textContent,
              heading: HeadingLevel.HEADING_2,
              spacing: { before: 240, after: 120 },
            });
          case 'h3':
            return new Paragraph({
              text: node.textContent,
              heading: HeadingLevel.HEADING_3,
              spacing: { before: 240, after: 120 },
            });
          case 'p':
            return new Paragraph({
              text: node.textContent,
              spacing: { before: 120, after: 120 },
            });
          case 'ul':
          case 'ol':
            return Array.from(node.children).map(li => 
              new Paragraph({
                text: `• ${li.textContent}`,
                spacing: { before: 120, after: 120 },
                indent: { left: 720 }, // 0.5 inch indent
              })
            );
          default:
            return new Paragraph({ text: node.textContent });
        }
      }
      return null;
    };

    // Process all nodes and add them to the document
    const paragraphs = Array.from(div.childNodes)
      .map(processNode)
      .flat()
      .filter(Boolean);

    doc.addSection({
      children: paragraphs,
    });

    // Generate and download the document
    Packer.toBlob(doc).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Indicaciones_para_el_Paciente.docx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast({
        title: 'DOCX descargado',
        description: 'Las indicaciones han sido descargadas como documento de Word.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const getDocumentTypeLabel = (type) => {
    switch (type) {
      case "complex_summary":
        return DocumentType.COMPLEX_SUMMARY;
      case "first_visit":
        return DocumentType.FIRST_VISIT;
      case "transcript_summary":
        return DocumentType.TRANSCRIPT_SUMMARY;
      default:
        return type;
    }
  };

  const getDocumentStatusLabel = status => {
    switch (status) {
      case 'ready':
        return DocumentStatus.READY;
      case 'pending':
        return DocumentStatus.PENDING;
      default:
        return status;
    }
  };

  const markdownComponents = {
    ...ChakraUIRenderer(),
    p: props => <Text fontSize="sm" fontFamily="body" mb={2} {...props} />,
    h1: props => (
      <Text
        fontSize="xl"
        fontWeight="bold"
        fontFamily="heading"
        mb={3}
        {...props}
      />
    ),
    h2: props => (
      <Text
        fontSize="lg"
        fontWeight="semibold"
        fontFamily="heading"
        mb={2}
        {...props}
      />
    ),
    h3: props => (
      <Text
        fontSize="md"
        fontWeight="medium"
        fontFamily="heading"
        mb={2}
        {...props}
      />
    ),
    ul: props => (
      <Box as="ul" pl={4} fontSize="sm" fontFamily="body" mb={2} {...props} />
    ),
    ol: props => (
      <Box as="ol" pl={4} fontSize="sm" fontFamily="body" mb={2} {...props} />
    ),
    li: props => (
      <Box as="li" fontSize="sm" fontFamily="body" mb={1} {...props} />
    ),
  };

  const handleGenerateIndications = async () => {
    if (!selectedDocument || selectedDocument.type_document !== "transcript_summary" || specialty !== "fertilidad") {
      return;
    }

    setIsGeneratingIndications(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_SUMMARIZATION_URL}/complex_summary/generate-indications`, {
        transcription: selectedDocument.content
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });


      // const generatedText = `**Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // **Informe de Consulta Médica sobre Tratamiento de Fertilidad**
      // // ... (rest of the mock text)`;

      // setGeneratedIndications(generatedText);

      setGeneratedIndications(response.data.indications);
      setIsIndicationsModalOpen(true);

      toast({
        title: 'Indicaciones generadas',
        description: 'Las indicaciones para el paciente han sido generadas exitosamente.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error generating indications:', error);
      toast({
        title: 'Error',
        description: 'No se pudieron generar las indicaciones. Por favor, intente de nuevo.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsGeneratingIndications(false);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(generatedIndications).then(() => {
      toast({
        title: 'Copiado al portapapeles',
        description: 'Las indicaciones han sido copiadas al portapapeles.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }).catch((err) => {
      console.error('Error copying to clipboard:', err);
      toast({
        title: 'Error',
        description: 'No se pudo copiar al portapapeles. Por favor, inténtelo de nuevo.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    });
  };

  const handleDownloadPDF = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 20;

    let cursorX = margin;
    let cursorY = margin;

    // Add title and patient info
    pdf.setFontSize(18);
    pdf.setTextColor(43, 108, 176);
    pdf.text('Indicaciones para el Paciente', cursorX, cursorY);
    cursorY += 10;

    pdf.setFontSize(12);
    pdf.setTextColor(0);
    pdf.text(`Paciente: ${selectedDocument?.patient_name || 'No disponible'}`, cursorX, cursorY);
    cursorY += 10;
    pdf.text(`Fecha: ${new Date().toLocaleDateString()}`, cursorX, cursorY);
    cursorY += 15;

    // Convert markdown to HTML
    const html = marked(generatedIndications);
    // Sanitize HTML
    const cleanHtml = DOMPurify.sanitize(html);

    // Create a temporary div to render the HTML
    const div = document.createElement('div');
    div.innerHTML = cleanHtml;

    // Function to add text with proper formatting
    const addFormattedText = (text, fontSize, isBold = false, indent = 0) => {
      pdf.setFontSize(fontSize);
      pdf.setFont(undefined, isBold ? 'bold' : 'normal');
      const lines = pdf.splitTextToSize(text, pageWidth - 2 * margin - indent);
      lines.forEach(line => {
        if (cursorY > pageHeight - margin) {
          pdf.addPage();
          cursorY = margin;
        }
        pdf.text(line, cursorX + indent, cursorY);
        cursorY += fontSize * 0.3527; // Convert pt to mm
      });
      cursorY += 5; // Add some space after each block
    };

    // Iterate through the HTML elements and add them to the PDF
    div.childNodes.forEach(node => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        switch (node.tagName.toLowerCase()) {
          case 'h1':
            addFormattedText(node.textContent, 18, true);
            break;
          case 'h2':
            addFormattedText(node.textContent, 16, true);
            break;
          case 'h3':
            addFormattedText(node.textContent, 14, true);
            break;
          case 'p':
            addFormattedText(node.textContent, 12);
            break;
          case 'ul':
          case 'ol':
            node.childNodes.forEach(li => {
              if (li.nodeType === Node.ELEMENT_NODE && li.tagName.toLowerCase() === 'li') {
                addFormattedText(`• ${li.textContent}`, 12, false, 5);
              }
            });
            break;
        }
      }
    });

    pdf.save('Indicaciones_para_el_Paciente.pdf');

    toast({
      title: 'PDF descargado',
      description: 'Las indicaciones han sido descargadas como PDF.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction={{ base: "column", md: "row" }} minH="100vh" bg={bgColor}>
      {/* Left Section */}
      <Box
        w={{ base: "100%", md: "25%" }}
        p={5}
        bg={bgColor}
        borderRight="1px solid"
        borderColor="gray.200"
      >
        <VStack spacing={6} align="stretch">
          <Image src={`${process.env.PUBLIC_URL}/logo.png`} alt="Medify AI Logo" maxW="220px" mx="auto" />
          <Box
            bg="gray.100"
            p={5}
            borderRadius="md"
            boxShadow="md"
          >
            <Heading as="h2" size="md" mb={3} textAlign="center">
              Mis Documentos
            </Heading>
            <Text fontSize="sm">
              1. En este sector encontrarás los resúmenes de tus consultas.
              <br /><br />
              2. Podrás encontrar los mismos por tipo de documento.
              <br /><br />
              3. Aguarda a que el Estado del documento sea "generado" para poder
              acceder al mismo.
            </Text>
          </Box>
          <Button
            leftIcon={<Icon as={FaSync} />}
            onClick={handleRefresh}
            w="full"
          >
            Actualizar Documentos
          </Button>
        </VStack>
      </Box>

      <Box flex={1} p={5}>
        <VStack spacing={8} align="stretch">
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Button
              leftIcon={<Icon as={FaArrowLeft} />}
              onClick={() => navigate(-1)}
              size="md"
            >
              Volver
            </Button>
            <Heading as="h1" size="xl">
              Mis Documentos
            </Heading>
            <Box width={24} /> {/* This empty Box helps center the heading */}
          </Flex>

          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {documents.map(document => (
              <Card key={document.id} variant="outline">
                <CardHeader>
                  <Heading size="md">
                    {document.patient_name || 'Nombre no disponible'}
                  </Heading>
                </CardHeader>
                <CardBody>
                  <Stack spacing={3}>
                    <Text fontSize="sm" color="gray.600">
                      Creado: {new Date(document.created).toLocaleString()}
                    </Text>
                    <Text>
                      <strong>Tipo:</strong> {getDocumentTypeLabel(document.type_document)}
                    </Text>
                    <Text
                      color={
                        document.state === 'ready'
                          ? 'green.500'
                          : document.state === 'pending'
                          ? 'orange.500'
                          : 'gray.500'
                      }
                      fontWeight="bold"
                    >
                      Estado: {getDocumentStatusLabel(document.state)}
                    </Text>
                  </Stack>
                </CardBody>
                <CardFooter>
                  {document.state === 'ready' ? (
                    <Button
                      colorScheme="blue"
                      onClick={() => handleViewDocument(document)}
                    >
                      Visualizar Documento
                    </Button>
                  ) : (
                    <Button isDisabled>Pendiente</Button>
                  )}
                </CardFooter>
              </Card>
            ))}
          </SimpleGrid>
        </VStack>
      </Box>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Detalles del Documento</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Text fontWeight="bold">Nombre del Paciente:</Text>
              {isEditing ? (
                <Input
                  value={editedPatientName}
                  onChange={handlePatientNameChange}
                  placeholder="Nombre del paciente"
                />
              ) : (
                <Text>{selectedDocument?.patient_name || 'No disponible'}</Text>
              )}

              <Text fontWeight="bold">Resumen:</Text>
              {isEditing ? (
                <Textarea
                  value={editedSummary}
                  onChange={handleSummaryChange}
                  minHeight="550px"
                  fontSize="sm"
                  fontFamily="body"
                />
              ) : (
                <Box
                  border="0px"
                  borderColor="gray.200"
                  borderRadius="md"
                  p={2}
                  fontSize="sm"
                  fontFamily="body"
                  maxHeight="550px"
                  overflowY="auto"
                >
                  <ReactMarkdown
                    components={markdownComponents}
                    children={selectedDocument?.summary || ''}
                    skipHtml
                  />
                </Box>
              )}

              <Text fontWeight="bold">Fecha de Creación:</Text>
              <Text fontSize="sm">
                {new Date(selectedDocument?.created).toLocaleString()}
              </Text>

              <Button
                onClick={handleEditToggle}
                colorScheme={isEditing ? 'red' : 'blue'}
              >
                {isEditing ? 'Cancelar Edición' : 'Editar Documento'}
              </Button>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleDownloadSummary}>
              Descargar Resumen
            </Button>
            {selectedDocument?.type_document === "transcript_summary" && specialty === "fertilidad" && (
              <Button
                leftIcon={<Icon as={FaClipboard} />}
                onClick={handleGenerateIndications}
                isLoading={isGeneratingIndications}
                loadingText="Generando..."
                colorScheme="teal"
                mr={3}
              >
                Generar Indicaciones
              </Button>
            )}
            {isEditing && (
              <Button colorScheme="green" onClick={handleSaveChanges}>
                Guardar Cambios
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Modal isOpen={isIndicationsModalOpen} onClose={() => setIsIndicationsModalOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent maxHeight="85vh" minWidth='85vh' display="flex" flexDirection="column" >
          <ModalHeader>Indicaciones para el Paciente</ModalHeader>
          <ModalCloseButton />
          <ModalBody 
            flex="1"
            overflowY="auto"
          >
            <Box
              border="1px"
              borderColor="gray.200"
              borderRadius="md"
              p={4}
            >
              <Heading as="h1" size="xl" mb={4} color="blue.600">
                Indicaciones para el Paciente
              </Heading>
              <Text fontSize="md" mb={4}>
                Paciente: {selectedDocument?.patient_name || 'No disponible'}
              </Text>
              <Text fontSize="md" mb={4}>
                Fecha: {new Date().toLocaleDateString()}
              </Text>
              <Box>
                <ReactMarkdown
                  components={markdownComponents}
                  children={generatedIndications}
                  skipHtml
                />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              leftIcon={<Icon as={FaDownload} />}
              onClick={handleDownloadPDF}
              colorScheme="green"
              mr={3}
            >
              Descargar PDF
            </Button>
            <Button
              leftIcon={<Icon as={FaFileWord} />}
              onClick={handleDownloadDOCX}
              colorScheme="blue"
              mr={3}
            >
              Descargar DOCX
            </Button>
            <Button
              leftIcon={<Icon as={FaCopy} />}
              onClick={handleCopyToClipboard}
              colorScheme="blue"
              mr={3}
            >
              Copiar al Portapapeles
            </Button>
            <Button variant="outline" onClick={() => setIsIndicationsModalOpen(false)}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default MyDocuments;